import axios from 'axios';

const DEBANK_API_KEY = 'd7ce86483da0c8ec8c6fb159578ac035c272fbbc';
const walletAddress = '0xfa71C8000CE627c30fDC50ae861F28fD692915A3';
const ethChain = { protocol: 'uniswap3', chainId: 'eth', name: 'Ethereum' };

// Function to fetch Ethereum Uniswap V3 positions
export const fetchEthPositions = async () => {
  try {
    const url = `https://pro-openapi.debank.com/v1/user/protocol?id=${walletAddress}&protocol_id=${ethChain.protocol}&chain_id=${ethChain.chainId}`;
    const headers = { 'accept': 'application/json', 'AccessKey': DEBANK_API_KEY };
    const response = await axios.get(url, { headers });
    const data = response.data;

    if (data?.portfolio_item_list?.length > 0) {
      const enrichedPositions = await Promise.all(
        data.portfolio_item_list.map(async (position) => {
          let claimableTokens = [];
          if (position.detail?.reward_token_list) {
            claimableTokens = await Promise.all(
              position.detail.reward_token_list.map(async (token) => {
                const price = await fetchTokenPrice(token.id, ethChain.chainId);  // Fetch price for each token
                return {
                  symbol: token.symbol,
                  amount: token.amount,
                  usdValue: price * token.amount,  // Convert to USD value
                };
              })
            );
          }
          return {
            ...position,
            chain: ethChain.name,  // Add chain info for easy table rendering
            claimableTokens,
          };
        })
      );
      return enrichedPositions;  // Return positions for Ethereum
    }
    return [];  // Return empty if no positions found
  } catch (error) {
    console.error(`Error fetching Ethereum Uniswap positions:`, error);
    return [];
  }
};

// Function to fetch token price
const fetchTokenPrice = async (tokenId, chainId) => {
  try {
    const response = await axios.get(
      `https://pro-openapi.debank.com/v1/token?chain_id=${chainId}&id=${tokenId}`,
      { headers: { 'accept': 'application/json', 'AccessKey': DEBANK_API_KEY } }
    );
    return response.data.price || 0;
  } catch (error) {
    console.error("Error fetching token price:", error);
    return 0;
  }
};