import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAuYq3BKrS7SLEhW1i_ZKL-q4l3OVHtKYI",
  authDomain: "arbland-4.firebaseapp.com",
  projectId: "arbland-4",
  storageBucket: "arbland-4.firebasestorage.app",
  messagingSenderId: "779134881506",
  appId: "1:779134881506:web:43b7383296c886ee6098bb",
  measurementId: "G-ZKQ8H682LC",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Function to log chart data to Firestore
export const logDataToFirestore = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "chartData"), data);
    console.log("Data written to Firestore with ID: ", docRef.id);
    console.log("Logged data:", data);  // Log the data being added
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

// Function to fetch chart data from Firestore
export const fetchChartDataFromFirestore = async () => {
  const q = query(
    collection(db, "chartData"),
    where("poolName", "==", "Total USD Value"),  // Filter for total value
    orderBy("timestamp", "desc"),
    limit(90)
  );
  const querySnapshot = await getDocs(q);
  const chartData = [];
  querySnapshot.forEach((doc) => {
    console.log("Fetched document data:", doc.data());  // Log each document fetched
    chartData.push(doc.data());
  });
  return chartData;
};