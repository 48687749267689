import React from "react";

const Modal = ({ closeModal, children, title, subTitle }) => {
    const close = () => {
        closeModal();
    };

    return (
        <div className="modal" onClick={close}>
            <div className="modal-inner" onClick={(e) => e.stopPropagation()}>
                <div className="modal-top-bar">
                    <div className="modal-top-bar-title-container relative">
                        <div className="modal-top-bar-title">
                            {title}
                        </div>
                        <div className="modal-top-bar-subtitle">
                            {subTitle}
                        </div>
                        <span
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '15px',
                                cursor: 'pointer'
                            }}
                            onClick={close}
                        >
                            close
                        </span>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;