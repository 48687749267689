import React, { useState, useEffect } from "react";
import EthLogo from "../../assets/eth.png"; // Import the ETH logo image

const EthPrice = () => {
    const [ethPrice, setEthPrice] = useState(null);

    useEffect(() => {
        const fetchEthPrice = async () => {
            const apiKey = 'XNHHX47HCVBA6UMCYHZRMCTDEQ3BV1K8ZD';
            const url = `https://api.arbiscan.io/api?module=stats&action=ethprice&apikey=${apiKey}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                if (data.status === "1") {
                    setEthPrice(Math.round(data.result.ethusd));
                } else {
                    console.error("Error fetching ETH price:", data.message);
                }
            } catch (error) {
                console.error("Error fetching ETH price:", error);
            }
        };

        fetchEthPrice();
        const intervalId = setInterval(fetchEthPrice, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    return (
        <div className="eth-price-container">
            <img src={EthLogo} alt="ETH logo" className="eth-logo" />
            <span>{ethPrice !== null ? `$${ethPrice}` : "Loading..."}</span> {/* Display price or loading */}
        </div>
    );
};

export default EthPrice;