import React from "react";
import { FaTelegram, FaDiscord, FaTwitter } from 'react-icons/fa'; // Importing icons

const Footer = ({ cssClass, toggleState, handleDragonClick, handlePortalClick, onCastleClick }) => {

    const handleOnClick = (event) => {
        event.stopPropagation();
        toggleState();
    }

    const handleDragonClickWithPropagation = (event) => {
        event.stopPropagation();
        handleDragonClick();
    }

    const handlePortalClickWithPropagation = (event) => {
        event.stopPropagation();
        handlePortalClick();
    }

    const onCastleClickWithPropagation = (event) => {
        event.stopPropagation();
        onCastleClick();
    }

    return (
        <div className={cssClass} onClick={handleOnClick}>

            <div className="footer-buttons-mobile">
                <div className="footer-icons">
                    <a href="https://t.me/cryptomischief2" target="_blank" rel="noopener noreferrer">
                        <FaTelegram size={24} />
                    </a>
                    <a href="https://discord.gg/V7cgwUfTuD" target="_blank" rel="noopener noreferrer">
                        <FaDiscord size={24} />
                    </a>
                    <a href="https://x.com/PXPwizards" target="_blank" rel="noopener noreferrer">
                        <FaTwitter size={24} />
                    </a>
                </div>

                <div className="footer-text">
                    <p>© 2024 Arbitrum Land. Join the $ETH adventure.</p>
                </div>

                <div className="footer-disclaimer">
                    <p>Disclaimer: This is not financial advice. Please do your own research.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;