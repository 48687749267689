import React from "react";
import bg from "../assets/bg-large1.png";
import "../App.scss";
import castle from "../assets/castle.png";
import dragons from "../assets/dragons.png";
import tower from "../assets/tower.png";
import portal from "../assets/portal.png";
import mine from "../assets/mine.png";
import spider from "../assets/spider.png";
import ufo from "../assets/ufo.png";

const ArbitrumLand = ({
  onCastleClick,
  onDragonClick,
  onPortalClick,
  onTowerClick,
  onMineClick,
  onSpiderClick,
  onUfoClick
}) => {
  const [hoveredTooltip, setHoveredTooltip] = React.useState(null);

  const handleMouseEnter = (tooltip) => {
    setHoveredTooltip(tooltip);
  };

  const handleMouseLeave = () => {
    setHoveredTooltip(null);
  };

  return (
    <div className="responsive-image-wrapper">
      <img src={bg} className="responsive-image" />
      <img
        onMouseEnter={() => handleMouseEnter("castle")}
        onMouseLeave={handleMouseLeave}
        onClick={onCastleClick}
        src={castle}
        className="castle-image"
      />
      <div
        className={`tooltip-container castle-tooltip ${hoveredTooltip === "castle" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("castle")}
        onMouseLeave={handleMouseLeave}
        onClick={onCastleClick}
      >
        Deposit $ETH into Castle Arb
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("ufo")}
        onMouseLeave={handleMouseLeave}
        onClick={onUfoClick}
        src={ufo}
        className="ufo-image"
      />
      <div
        className={`tooltip-container ufo-tooltip ${hoveredTooltip === "ufo" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("ufo")}
        onMouseLeave={handleMouseLeave}
        onClick={onUfoClick}
      >
        The Referral Tree
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("tower")}
        onMouseLeave={handleMouseLeave}
        onClick={onTowerClick}
        src={tower}
        className="tower-image"
      />
      <div
        className={`tooltip-container tower-tooltip ${hoveredTooltip === "tower" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("tower")}
        onMouseLeave={handleMouseLeave}
        onClick={onTowerClick}
      >
        Buy Boost Potions to Increase Your Rewards
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("spider")}
        onMouseLeave={handleMouseLeave}
        onClick={onSpiderClick}
        src={spider}
        className="spider-image"
      />
      <div
        className={`tooltip-container spider-tooltip ${hoveredTooltip === "spider" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("spider")}
        onMouseLeave={handleMouseLeave}
        onClick={onSpiderClick}
      >
        Buy the SCC NFT to Join Our DAO
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("portal")}
        onMouseLeave={handleMouseLeave}
        onClick={onPortalClick}
        src={portal}
        className="portal-image"
      />
      <div
        className={`tooltip-container portal-tooltip ${hoveredTooltip === "portal" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("portal")}
        onMouseLeave={handleMouseLeave}
        onClick={onPortalClick}
      >
        Claim your $ETH Rewards
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("dragons")}
        onMouseLeave={handleMouseLeave}
        onClick={onDragonClick}
        src={dragons}
        className="dragons-image"
      />
      <div
        className={`tooltip-container dragon-tooltip ${hoveredTooltip === "dragons" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("dragons")}
        onMouseLeave={handleMouseLeave}
        onClick={onDragonClick}
      >
        Dragons! Compound your Rewards
      </div>

      <img
        onMouseEnter={() => handleMouseEnter("mine")}
        onMouseLeave={handleMouseLeave}
        onClick={onMineClick}
        src={mine}
        className="mine-image"
      />
      <div
        className={`tooltip-container mine-tooltip ${hoveredTooltip === "mine" ? "show-tooltip" : ""}`}
        onMouseEnter={() => handleMouseEnter("mine")}
        onMouseLeave={handleMouseLeave}
        onClick={onMineClick}
      >
        Visit Polygon Land Miner
      </div>
    </div>
  );
};

export default ArbitrumLand;