import React, { useState } from "react";
import { FaCopy } from 'react-icons/fa';

const ShortenLink = ({ longUrl }) => {
  const [shortLink, setShortLink] = useState(null);
  const [isShortLinkLoading, setIsShortLinkLoading] = useState(false);

  const shortenLink = async () => {
    setIsShortLinkLoading(true); // Start loading

    try {
      const response = await fetch('https://smolurl.com/api/links', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: longUrl })
      });

      const result = await response.json();
      setShortLink(result.data.short_url); // Set the short URL
    } catch (error) {
      console.error('Error shortening URL:', error);
    }

    setIsShortLinkLoading(false); // Stop loading
  };

  const copyToClipboard = async (textToCopy) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
      alert("Link copied!");
    } catch (error) {
      alert(`Error copying to clipboard: ${error}`);
    }
  };

  return (
    <div>
      {/* Button to shorten the referral link */}
      <button
        onClick={shortenLink}
        disabled={isShortLinkLoading}
      >
        {isShortLinkLoading ? 'Shortening...' : 'Get Short Link'}
      </button>

      {/* Display the shortened link if available */}
      {shortLink && (
        <p className="short-link">
          SHORT LINK: <br />
          <span onClick={() => copyToClipboard(shortLink)}>
            {shortLink}
          </span>
          <FaCopy
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={() => copyToClipboard(shortLink)}
          />
        </p>
      )}
    </div>
  );
};

export default ShortenLink;