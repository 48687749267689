import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal";
import { InfoBox, InfoBoxContainer } from "../../themes/global";
import { FaCopy } from 'react-icons/fa';
import ShortenLink from '../ShortenLink';
import { fetchEthPrice } from "../header/EthPrice"; // Import the function

const Castle = ({ closeModal }) => {
  useEffect(() => {
    document.body.classList.add('castle-modal-open');
    return () => {
      document.body.classList.remove('castle-modal-open');
    };
  }, []);

  const close = () => {
    window.clearAllIntervals();
    closeModal();
  };

  let refAddress = "0x0000000000000000000000000000000000000000";

  const [depositCooldown, setDepositCooldown] = useState(0);
  const [actionCooldown, setActionCooldown] = useState(0);
  const [referralRoyalties, setReferralRoyalties] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [availableRewardsInEth, setAvailableRewardsInEth] = useState(0);
  const [userDeposits, setUserDeposits] = useState(0);
  const [totalLocked, setTotalLocked] = useState(0);  // This tracks the total value locked
  const [maxPayoutReached, setMaxPayoutReached] = useState(false);
  const [contractHasStarted, setContractHasStarted] = useState(false);
  const [totalPayout, setTotalPayout] = useState(0);
  const [miners, setMiners] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [minDeposit, setMinDeposit] = useState("0.003");
  const [referralTier, setReferralTier] = useState(null);
  const [refLink, setRefLink] = useState(`${window.location.origin}?ref=${window.currentAddr}`);
  const [ethPrice, setEthPrice] = useState(null); // Track ETH price
  const [usdClaimedValue, setUsdClaimedValue] = useState(null); // Track USD value of claimed ETH
  const [usdRewardsValue, setUsdRewardsValue] = useState(null); // Track USD value of ETH rewards
  const [ethBalance, setEthBalance] = useState(null); // Track ETH balance of connected wallet


  const calculateDepositCD = (lastAction, contractDepositCD) => {
    const now = Math.floor(new Date().getTime() / 1000);
    let timeLeft = lastAction + contractDepositCD - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Deposit CD", timeLeft);
    return timeLeft;
  };

  const fetchEthBalance = async () => {
    try {
      if (window.hasValidWeb3Connection && window.currentAddr) {
        const balance = await window.web3.eth.getBalance(window.currentAddr); // Fetch balance in Wei
        setEthBalance(window.web3.utils.fromWei(balance, "ether")); // Convert balance from Wei to ETH
      }
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  useEffect(() => {
    fetchEthBalance();
  }, [window.currentAddr]); // Fetch balance when wallet address changes

  const fetchEthPrice = async () => {
    const apiKey = 'XNHHX47HCVBA6UMCYHZRMCTDEQ3BV1K8ZD';
    const url = `https://api.arbiscan.io/api?module=stats&action=ethprice&apikey=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "1") {
        return Math.round(data.result.ethusd);
      } else {
        console.error("Error fetching ETH price:", data.message);
      }
    } catch (error) {
      console.error("Error fetching ETH price:", error);
    }
  };

  useEffect(() => {
    const calculateUsdValues = async () => {
      const price = await fetchEthPrice(); // Fetch ETH price
      setEthPrice(price);

      if (price && totalPayout) {
        setUsdClaimedValue((totalPayout * price).toFixed(2)); // Calculate USD equivalent for claimed
      }

      if (price && availableRewardsInEth) {
        setUsdRewardsValue((availableRewardsInEth * price).toFixed(2)); // Calculate USD equivalent for rewards
      }
    };

    calculateUsdValues();
  }, [totalPayout, availableRewardsInEth]); // Recalculate when either totalPayout or availableRewardsInEth changes

  const startDepositCDCalculation = (lastAction, contractDepositCD) => {
    setInterval(() => {
      let timeLeft = calculateDepositCD(lastAction, contractDepositCD);
      setDepositCooldown(timeLeft);
    }, 1000);
  };

  const calculateActionCD = (lastAction, actionCoolDown) => {
    const now = Math.floor(new Date().getTime() / 1000);
    let timeLeft = lastAction + actionCoolDown - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Action CD", timeLeft);
    return timeLeft;
  };

  const startActionCDCalculation = (lastAction, contractActionCD) => {
    setInterval(() => {
      const timeLeft = calculateActionCD(lastAction, contractActionCD);
      setActionCooldown(timeLeft);
    }, 1000);
  };

  const copyToClipboard = async (textToCopy) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          document.execCommand("copy");
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
      alert("Ref link copied!");
    } catch (error) {
      alert(`Error copying to clipboard: ${error}`);
    }
  };

  const fetchContractBalance = async () => {
    try {
      const balanceInWei = await window.contract.methods.getBalance().call();  // Fetch balance in Wei
      const balanceInEth = window.web3.utils.fromWei(balanceInWei, "ether");  // Convert to Ether
      console.log("Contract Balance in ETH:", balanceInEth);  // Log the contract balance
      setTotalLocked(balanceInEth);  // Update the state to display on the UI
    } catch (error) {
      console.error("Error fetching contract balance:", error);
    }
  };
  // Call this function when the component mounts
  useEffect(() => {
    fetchContractBalance();
  }, []);

  const updateStateValues = async (userInfo) => {
    if (window.hasValidWeb3Connection === true) {
      const minDepositReponse = await window.contract.methods.MIN_DEPOSIT().call();
      setMinDeposit(window.readableETH(minDepositReponse, 3));

      if (userInfo === undefined || userInfo === null) {
        return;
      }
      console.log("UserInfo:", userInfo);
      console.log("currentAddr:", window.currentAddr);
      if (window.currentAddr) {
        const contractStarted = await window.contract.methods.contractStarted().call();
        setContractHasStarted(contractStarted);

        const getmaxPayoutReached = await window.contract.methods.maxPayoutReached(window.currentAddr).call();
        if (getmaxPayoutReached) setMaxPayoutReached(getmaxPayoutReached);

        const rewards = await window.contract.methods.getAvailableRewards(window.currentAddr).call();
        rewards && setAvailableRewards(rewards);
        const rewardsInEth = await window.contract.methods.calculateSell(window.currentAddr).call();
        rewardsInEth && setAvailableRewardsInEth(window.readableETH(rewardsInEth, 5));

        const totalDeposits = await window.contract.methods.getUserInfo(window.currentAddr).call();
        totalDeposits && setUserDeposits(window.readableETH(totalDeposits._totalDeposits, 3));

        const depositCoolDown = await window.contract.methods.DEPOSIT_COOLDOWN().call();
        let depositTimeLeft = calculateDepositCD(parseInt(userInfo._lastAction), parseInt(depositCoolDown));
        setDepositCooldown(depositTimeLeft);
        startDepositCDCalculation(parseInt(userInfo._lastAction), parseInt(depositCoolDown));

        const actionCoolDown = await window.contract.methods.ACTION_COOLDOWN().call();
        const actionTimeLeft = calculateActionCD(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
        setActionCooldown(actionTimeLeft);
        startActionCDCalculation(parseInt(userInfo._lastAction), parseInt(actionCoolDown));

        userInfo?._totalWithdrawals && setTotalPayout(window.readableETH(userInfo._totalWithdrawals, 5));
        userInfo?._referralRewards && setReferralRoyalties(window.readableETH(userInfo._referralRewards, 5));
      }
      setMiners(userInfo._miners);

      const tier = await window.contract.methods.calcRefRewardTierValue(window.currentAddr).call();
      setReferralTier(tier);
      return userInfo;
    }
  };

  const deposit = async () => {
    setLoading(true);
    if (depositAmount <= 0 || depositAmount === "") {
      alert("Ya gotta deposit something, buddy.");
      return;
    }

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let linkedRefAddress = params.ref;
    refAddress = linkedRefAddress && linkedRefAddress !== "" && linkedRefAddress !== null ? linkedRefAddress : refAddress;
    await window.deposit(depositAmount, refAddress);
    const userInfo = await window.getUserInfo();
    window.clearAllIntervals();
    await updateStateValues(userInfo);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    window.clearAllIntervals();
    (async () => {
      const userInfo = await window.getUserInfo();
      await updateStateValues(userInfo);
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <Modal closeModal={close} title={
    <div style={{ textAlign: 'center' }}>
        <span className="castle-title">Castle Arb</span>
        <h5 style={{ margin: 0, color: '#888', textTransform: 'none' }}>Your journey starts here</h5>
    </div>
}>
        <p className="refLink">
          MY REFERRAL LINK: <br />
          <span onClick={(e) => copyToClipboard(e.target?.innerText)}>
            {`${window.location.origin}?ref=${window.currentAddr}`}
          </span>
          <FaCopy
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={(e) => copyToClipboard(`${window.location.origin}?ref=${window.currentAddr}`)}
          />
        </p>
        <ShortenLink longUrl={`${window.location.origin}?ref=${window.currentAddr}`} />

        <div className="input-pair">
          <input
            disabled={loading || !window.hasValidWeb3Connection || depositCooldown > 0}
            type="number"
            placeholder={`${minDeposit} $ETH MIN`}
            onChange={(e) => setDepositAmount(e.target.value)}
          />
          <button
            disabled={loading || !window.hasValidWeb3Connection || depositCooldown > 0 || contractHasStarted === false}
            className="primary-button"
            onClick={deposit}
          >
            DEPOSIT $ETH
          </button>
        </div>

        <div className="centered-balance">
        {ethBalance !== null ? (
          <p>Your Balance: {Number(ethBalance).toFixed(4)} $ETH</p>
         ) : (
          <p>Fetching Balance...</p>
        )}
       </div>

        <InfoBoxContainer>
        {!window.hasValidWeb3Connection && (
        <div className="warning-banner">
         PLEASE CONNECT YOUR WALLET ON ARBITRUM NETWORK TO VIEW DETAILS AND JOIN ARBLAND.
        </div>
        )}

<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text">REWARDS</h3>
  <div className="rewards-wrapper">
    <span className="info-box-text">{parseInt(availableRewards)?.toLocaleString()} KNIGHTS</span>
    <br />
    <span className="info-box-text">
      {availableRewardsInEth} $ETH ({usdRewardsValue ? `$${usdRewardsValue} USD` : "Calculating..."})
    </span>
  </div>
</InfoBox>
<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text"> MY KNIGHTS</h3>
  <p className="info-box-text">{parseInt(miners)?.toLocaleString()}</p>
</InfoBox>
<InfoBox className="highlighted-info-box">
  <h3 className="info-box-text"> CLAIMED</h3>
  <p className="info-box-text">{totalPayout} $ETH</p> {/* Display ETH amount */}
  <p className="info-box-text">({usdClaimedValue ? `$${usdClaimedValue} USD` : "Calculating..."})</p> {/* Display USD equivalent */}
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">TVL</h3>
  <p className="info-box-text">{Number(totalLocked).toFixed(4)} $ETH</p>  {/* Limiting to 4 decimal places */}
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">REFERRALS</h3>
  <p className="info-box-text">{referralRoyalties} $ETH</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">MY DEPOSITS</h3> {/* Updated label */}
  <p className="info-box-text">{Number(userDeposits).toFixed(3)} $ETH</p> {/* Limiting to 3 decimal places */}
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">DEPOSIT COOLDOWN</h3>
  <p className="info-box-text">{depositCooldown} HOURS</p>
</InfoBox>
<InfoBox>
  <h3 className="info-box-text">ACTION COOLDOWN</h3>
  <p className="info-box-text">{actionCooldown} HOURS</p>
</InfoBox>
<InfoBox className="info-box max-payout">
  <h3 className="info-box-text">MAX PAYOUT REACHED?</h3>
  <p className="info-box-text">{maxPayoutReached ? "YES" : "NO"}</p>
</InfoBox>
<InfoBox className="info-box referral-tier">
  <h3 className="info-box-text">REFERRAL TIER</h3>
  <p className="info-box-text">
    {referralTier !== null ? (referralTier / 10).toFixed(1).replace(/\.0$/, '') : 'Loading...'}
  </p>
</InfoBox>
<p className="referral-info">
  EARN $ETH DIRECTLY INTO YOUR WALLET THROUGH OUR 1% TO 20% REFERRAL SYSTEM
</p>
        </InfoBoxContainer>
      </Modal>
    </div>
  );
};

export default Castle;