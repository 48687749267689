import React, { useState } from "react";

const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (questionIndex) => {
    setOpenQuestion(openQuestion === questionIndex ? null : questionIndex);
  };

  // Style for the answer box with gradient background and border
  const boxStyle = {
    padding: '15px',
    margin: '15px 0',
    borderRadius: '8px',
    background: 'linear-gradient(135deg, rgba(50, 23, 77, 0.5), rgba(25, 57, 37, 0.5))', // Transparent gradient
    border: '2px solid #00ccffd1', // Light blue border
    color: 'white',
  };

  return (
    <div style={{ marginTop: '20px', fontSize: '1.2em', color: 'white', textAlign: 'center', lineHeight: '1.8em' }}>
      <h3 style={{ marginBottom: '15px', fontSize: '1.5em' }}>FAQ: How the Yield Boost System Works</h3>

      <div>
  <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(1)}>
          <strong>1. What are Yield Boosts?</strong>
        </p>
        {openQuestion === 1 && (
          <div style={boxStyle}>
            <p>Yield Boosts are enhancements that allow you to earn a higher percentage on your rewards.</p>
            <p><strong>Level 1 Boost:</strong> Increases yield by 10% and costs 0.024 $ETH.</p>
            <p><strong>Level 2 Boost:</strong> Increases yield by 30% and costs 0.08 $ETH.</p>
          </div>
        )}
      </div>


      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(2)}>
          <strong>2. How do I qualify for Level 1 Boost?</strong>
        </p>
        {openQuestion === 2 && (
          <p style={boxStyle}>
            To qualify for Level 1 Boost, you must first claim the cost of the boost from the contract to meet the minimum requirement. Once you reach this threshold, the &quot;Buy Boost&quot; button will become available, and you can purchase the Level 1 Boost to increase your yield.
          </p>
        )}
      </div>

      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(3)}>
          <strong>3. How do I qualify for Level 2 Boost?</strong>
        </p>
        {openQuestion === 3 && (
          <p style={boxStyle}>
            After purchasing Level 1 Boost, you need to withdraw the cost of the Level 2 Boost. Once the total amount of withdrawals meets the combined threshold for Level 1 and Level 2, you can purchase the second boost to further increase your yield.
          </p>
        )}
      </div>

      <div>
  <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(4)}>
    <strong>4. Do I need an SCC NFT to use Boosts?</strong>
  </p>
  {openQuestion === 4 && (
    <div style={boxStyle}>
      <p>
        Yes, owning an SCC NFT is required to purchase yield boosts. If you don&apos;t already have an SCC NFT, you can buy one on <a href="https://opensea.io/collection/shitcoin-club-poops" target="_blank" rel="noopener noreferrer" style={{ color: '#00ccff' }}>OpenSea</a>, and an option to <a href="https://docs.google.com/forms/d/e/1FAIpQLSdRMKPlxpxcX52xwDD43_I6k5JFYHXSWHY8tIEpooulJbMqeg/viewform" target="_blank" rel="noopener noreferrer" style={{ color: '#00ccff' }}>apply for whitelist</a> will appear once you meet the withdrawal requirements. The application process will take 24 hours.
      </p>
    </div>
  )}
</div>

      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(5)}>
          <strong>5. What happens after I purchase a Boost?</strong>
        </p>
        {openQuestion === 5 && (
          <p style={boxStyle}>
            Once you&apos;ve successfully purchased a boost, the boost level is applied to your account, and you will see an increase in the yield percentage. If you&apos;ve reached the maximum boost level (Level 2), you will no longer be able to purchase additional boosts.
          </p>
        )}
      </div>

      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(6)}>
          <strong>6. What is the cost of each Boost?</strong>
        </p>
        {openQuestion === 6 && (
          <p style={boxStyle}>
            The cost of each boost is determined in $ETH and is displayed when you&apos;re eligible to purchase. The price varies between Level 1 and Level 2 boosts and is based on the current price or $ETH and any promotions Arbland may be running. Check the <a href="https://cryptomischief.io/wp-content/uploads/Arbitrum-Land-WP.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#00ccff' }}>Whitepaper</a> for the current boost costs.
          </p>
        )}
      </div>

      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(7)}>
          <strong>7. Can I purchase multiple Boosts?</strong>
        </p>
        {openQuestion === 7 && (
          <p style={boxStyle}>
            No, you can only purchase up to two boosts. Once you reach Level 2 Boost, you cannot buy any more boosts as this is the maximum yield increase you can achieve.
          </p>
        )}
      </div>

      <div>
        <p style={{ cursor: 'pointer' }} onClick={() => toggleQuestion(8)}>
          <strong>8. What if I haven&apos;t claimed enough $ETH?</strong>
        </p>
        {openQuestion === 8 && (
          <p style={boxStyle}>
            If you haven&apos;t claimed enough $ETH, the boost button will remain disabled, and a a tooltip will inform you that you need to claim out more $ETH to qualify for the next boost.
          </p>
        )}
      </div>
    </div>
  );
};

export default FAQ;