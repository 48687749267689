import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal";
import { InfoBox, InfoBoxContainer } from "../../themes/global";
import potion1 from "../../assets/boost1.jpg";
import potion2 from "../../assets/boost2.jpg";
import circleLogoGif from "../../assets/circlelogo.gif";
import noBoostImage from "../../assets/noboost.jpg";
import FAQ from "./FAQ"; // Import the FAQ component

const Tower = ({ closeModal }) => {
    const [userBoostLevel, setUserBoostLevel] = useState(0);
    const [canBuyBoost, setCanBuyBoost] = useState(false);
    const [applyForNftBoost, setApplyForNftBoost] = useState(false);
    const [loading, setLoading] = useState(true);
    const [level1Cost, setLevel1Cost] = useState(""); // Store level 1 cost in ETH
    const [level2Cost, setLevel2Cost] = useState(""); // Store level 2 cost in ETH
    const [level1, setLevel1] = useState(0); // Store level 1 boost percentage
    const [level2, setLevel2] = useState(0); // Store level 2 boost percentage
    const [boostMessage, setBoostMessage] = useState(""); // Store message below the button
    const [totalClaimed, setTotalClaimed] = useState(""); // Store user's total claimed ETH

    useEffect(() => {
        if (userBoostLevel > 0) {
            setTimeout(() => {}, 1000); // Remove sparkle after animation (if any sparkle functionality is re-added)
        }
    }, [userBoostLevel]);

    const updateStateValues = async () => {
        setLoading(true);
        if (window.hasValidWeb3Connection === true) {
            const userInfo = await window.getUserInfo();
            if (userInfo === undefined || userInfo === null) {
                return;
            }
            setUserBoostLevel(Number(userInfo._yieldLevelBoost));

            // Fetch YIELD_BOOST_LEVEL1_COST and YIELD_BOOST_LEVEL2_COST
            const level1ETH = BigInt(await window.contract.methods.YIELD_BOOST_LEVEL1_COST().call());
            const level2ETH = BigInt(await window.contract.methods.YIELD_BOOST_LEVEL2_COST().call());

            // Fetch boost percentages for Level 1 and Level 2
            const level1Percentage = Number(await window.contract.methods.YIELD_BOOST_LEVEL1().call());
            const level2Percentage = Number(await window.contract.methods.YIELD_BOOST_LEVEL2().call());

            // Fetch user's total withdrawals
            const totalWithdrawals = BigInt(userInfo._totalWithdrawals); // User's claimed ETH
            setTotalClaimed(window.readableETH(totalWithdrawals.toString(), 6)); // Set claimed ETH value with 6 decimals

            // Store the human-readable ETH values and boost percentages
            setLevel1Cost(window.readableETH(level1ETH.toString(), 3)); // Convert to ETH, rounded to 3 decimals
            setLevel2Cost(window.readableETH(level2ETH.toString(), 3)); // Convert to ETH, rounded to 3 decimals
            setLevel1(level1Percentage / 10); // Convert percentage to a number
            setLevel2(level2Percentage / 10); // Convert percentage to a number

            let nextBoostCost;
            if (userBoostLevel === 0) {
                // First boost: only check against level1ETH
                nextBoostCost = level1ETH;
            } else if (userBoostLevel === 1) {
                // Second boost: check against level2ETH only, since level 1 is already achieved
                nextBoostCost = level2ETH;
            } else {
                nextBoostCost = BigInt(0); // No further boosts available
            }

            // Other boost logic, early access, tooltip, etc.
            const earlyAccessEnabled = await window.contract.methods.addressHasEarlyAccess(window.currentAddr).call();

            if (Number(userInfo._yieldLevelBoost) >= 2) {
                setCanBuyBoost(false);
                setBoostMessage("Max Boost Achieved");
                setApplyForNftBoost(false);  // No need to apply if max boost is achieved
            } else if (!earlyAccessEnabled) {
                setCanBuyBoost(false);
                setBoostMessage("Prove NFT ownership.");
                setApplyForNftBoost(true);  // Set true if the user needs to apply for the NFT boost
            } else {
                setApplyForNftBoost(false); // Reset if early access is proven
                if (Number(userInfo._yieldLevelBoost) === 0 && totalWithdrawals >= level1ETH) {
                    setCanBuyBoost(true);
                    setBoostMessage(""); // Clear message if the button is available
                } else if (Number(userInfo._yieldLevelBoost) === 1 && totalWithdrawals >= (level1ETH + level2ETH)) {
                    setCanBuyBoost(true);
                    setBoostMessage(""); // Clear message if the button is available
                } else {
                    setCanBuyBoost(false);
                    setBoostMessage("You have not claimed enough $ETH to purchase the next boost.");
                }
            }
        }
        setLoading(false);
    };

    const close = () => {
        closeModal();
    };

    const applyForNft = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSdRMKPlxpxcX52xwDD43_I6k5JFYHXSWHY8tIEpooulJbMqeg/viewform");
    };

    const buyYieldLevelBoost = async () => {
        let levelCost = BigInt(await window.contract.methods.YIELD_BOOST_LEVEL1_COST().call());
        if (userBoostLevel === 1) {
            levelCost = BigInt(await window.contract.methods.YIELD_BOOST_LEVEL2_COST().call());
        }
        if (userBoostLevel >= 2) {
            alert("You have already reached the highest boost");
            return;
        }
        await window.buyYieldLevelBoost(userBoostLevel + 1, levelCost.toString());
        await updateStateValues();
    };

    useEffect(() => {
        (async () => {
            await updateStateValues();
        })();
    }, []);

    return (
        <div>
            <div className="tower-gif-container">
                <img src={circleLogoGif} alt="Circle Logo GIF" className="tower-logo-gif" />
            </div>

            <Modal closeModal={close} title={
    <div style={{ textAlign: 'center' }}>
        <span className="tower-modal-title">Wizard&apos;s Tower</span>
        <h6 style={{ margin: 0, color: '#888' }}>Feel the Power! Glug..Glug...Glug</h6>
    </div>
}>
                <InfoBoxContainer style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                    {/* Combined Box for Boost Level and Boost Image */}
                    <InfoBox style={{ flex: '1 1 45%', marginBottom: '20px', position: 'relative', overflow: 'hidden' }}>
                        <h1 className="boost-level-text">Boost Level</h1>
                        <h2 className="boost-level-text">{userBoostLevel}</h2>
                        {userBoostLevel === 1 && (
                            <>
                                <p>{level1Cost} $ETH - {level1}% Yield Boost</p>
                                <img className="boost-image" src={potion1} alt="Brew of Bounty" />
                            </>
                        )}
                        {userBoostLevel === 2 && (
                            <>
                                <p>{level2Cost} $ETH - {level2}% Yield Boost</p>
                                <img className="boost-image" src={potion2} alt="Tonic of Triumph" />
                            </>
                        )}
                        {userBoostLevel === 0 && (
                            <img className="boost-image" src={noBoostImage} alt="No Boost" />
                        )}
                    </InfoBox>

                    {/* Display the Boost Prices */}
                    <InfoBox style={{ flex: '1 1 45%', marginBottom: '20px', textAlign: 'center' }}>
                        <h1>Current Boost Prices</h1>
                        <h2>Brew of Bounty: {level1Cost} $ETH</h2>
                        <h2>Tonic of Triumph: {level2Cost} $ETH</h2>

                        {/* Display the claimed ETH */}
                        {userBoostLevel < 2 && (
                            <h3>You have claimed {totalClaimed} $ETH so far.</h3>
                        )}

                        {/* Centering the Buy Boost button */}
                        <div style={{ marginTop: '20px', position: 'relative' }}>
    {!applyForNftBoost && (
        <button
            disabled={!canBuyBoost || loading}
            className="potion-button"
            onClick={() => buyYieldLevelBoost()}
        >
            {userBoostLevel >= 2 ? "Max Boost Achieved" : "Buy Boost"}
        </button>
    )}
    {applyForNftBoost && (
        <button className="potion-button h80px" onClick={() => applyForNft()}>
            Apply for Boost Potions
        </button>
    )}
</div>
         
                                                {/* Display message under the button */}
                                                {boostMessage && (
                            <p style={{ color: userBoostLevel >= 2 ? '#00f' : '#808080', marginTop: '10px' }}>
                                {boostMessage}
                            </p>
                        )}
                    </InfoBox>

                </InfoBoxContainer>

                {/* Display FAQ Component */}
                <FAQ />
            </Modal>
        </div>
    );
};

export default Tower;