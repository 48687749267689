import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { fetchStakesAndClaimable } from './paca';  // Import BSC staking function
import { logDataToFirestore } from './firebaseConfig';  // Import Firebase logging function
import { fetchEthPositions } from './ethPositions';  // Import ETH positions script
import { fetchInjectedValue } from './fetchInjectedValue';  // Corrected import path

// Register required Chart.js components and plugins
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const DEBANK_API_KEY = 'd7ce86483da0c8ec8c6fb159578ac035c272fbbc';
const walletAddress = '0xa56a6a01282d020ef63eff6a3f6338f26fbfad37';
const protocol = 'arb_uniswap3';
const chainId = 'arb';

const STORAGE_VERSION = "1.1"; // Update this whenever you make changes
const CURRENT_VERSION_KEY = "usdValueLogVersion";

const UniswapDashboard = () => {
  const [positions, setPositions] = useState([]);  // Multiple positions
  const [bscStakes, setBscStakes] = useState({ combinedStakeValueUSD: 0, claimableUSD: 0 });  // BSC staking data
  const [loading, setLoading] = useState(true);
  const [usdValueLog, setUsdValueLog] = useState([]);  // Combined USD value log
  const [injectedValue, setInjectedValue] = useState(0);  // State for injected value

  useEffect(() => {
    
     // Clear outdated data if version mismatch
     const storedVersion = localStorage.getItem(CURRENT_VERSION_KEY);
     if (storedVersion !== STORAGE_VERSION) {
       localStorage.removeItem('usdValueLog'); // Clear outdated chart data
       localStorage.setItem(CURRENT_VERSION_KEY, STORAGE_VERSION); // Set the new version
     }
    
    const loadData = () => {
      const storedData = JSON.parse(localStorage.getItem('usdValueLog')) || [];
      setUsdValueLog(storedData);  // Load chart data from localStorage
    };

    loadData();

        // Fetch injected value from the smart contract
        const fetchInjected = async () => {
          try {
            const value = await fetchInjectedValue();
            setInjectedValue(value);
          } catch (error) {
            console.error('Error fetching injected value:', error);
          }
        };

    const fetchUniswapV3Positions = async () => {
      try {
        // Fetch Arbitrum positions
        const url = `https://pro-openapi.debank.com/v1/user/protocol?id=${walletAddress}&protocol_id=${protocol}&chain_id=${chainId}`;
        const headers = { 'accept': 'application/json', 'AccessKey': DEBANK_API_KEY };
        const response = await axios.get(url, { headers });
        const data = response.data;

        let arbPositions = [];
        if (data.portfolio_item_list && data.portfolio_item_list.length > 0) {
          arbPositions = await Promise.all(data.portfolio_item_list.map(async position => {
            let claimableTokens = [];
            if (position.detail && position.detail.reward_token_list) {
              claimableTokens = await Promise.all(position.detail.reward_token_list.map(async token => {
                const price = await fetchTokenPrice(token.id);  // Fetch price for each token
                return {
                  symbol: token.symbol,
                  amount: token.amount,
                  usdValue: price * token.amount  // Convert to USD value
                };
              }));
            }
            return {
              ...position,
              claimableTokens,
            };
          }));
        }

        // Fetch ETH positions from separate script
        const ethPositions = await fetchEthPositions();

        // Combine both Arbitrum and ETH positions
        const combinedPositions = [...arbPositions, ...ethPositions];

        setPositions(combinedPositions);

      } catch (error) {
        console.error('Error fetching Uniswap V3 positions:', error.response?.data || error.message);
      } finally {
        setLoading(false);
      }
    };

    // Fetch BSC staking data
    const fetchBSCData = async () => {
      try {
        const bscData = await fetchStakesAndClaimable();
        setBscStakes(bscData);
      } catch (error) {
        console.error('Error fetching BSC staking data:', error);
      }
    };

    fetchUniswapV3Positions();
    fetchBSCData();  // Fetch BSC data after Uniswap data
    fetchInjected(); // Fetch injected value
  }, []);

  // Function to fetch token prices in USD
  const fetchTokenPrice = async (tokenId) => {
    try {
      const response = await axios.get(`https://pro-openapi.debank.com/v1/token?chain_id=${chainId}&id=${tokenId}`, {
        headers: { 'accept': 'application/json', 'AccessKey': DEBANK_API_KEY }
      });
      return response.data.price || 0;  // Return price in USD
    } catch (error) {
      console.error('Error fetching token price:', error.response?.data || error.message);
      return 0;
    }
  };

  // Only log and update the chart when both Uniswap positions and PACA data are ready
  useEffect(() => {
    if (positions.length > 0 && bscStakes.combinedStakeValueUSD > 0) {
      const logValues = async () => {
        // Total value as shown in the table
        const totalUsdValue = (
          positions.reduce((total, pos) => total + (pos?.stats?.net_usd_value || 0), 0)
          + parseFloat(bscStakes.combinedStakeValueUSD)  // Add BSC staking value
        ).toFixed(2);
        // Log the combined total
        const totalLogData = {
          date: new Date().toLocaleDateString(),
          poolName: "Total USD Value",
          value: totalUsdValue,  // Use the same total as in the table
          timestamp: Date.now()
        };
        // Log the new data and store in localStorage
        const updatedLog = [...usdValueLog, totalLogData].slice(-90);  // Keep last 90 entries
        console.log("Logging total value for chart:", totalLogData);  // Debugging log
        setUsdValueLog(updatedLog);
        localStorage.setItem('usdValueLog', JSON.stringify(updatedLog));  // Save to localStorage

        // Log the data to Firebase Firestore
        try {
          await logDataToFirestore(totalLogData);  // Call Firebase function to log the data
        } catch (error) {
          console.error("Error logging to Firebase:", error);
        }
      };

      const interval = setInterval(logValues, 360000);  // Log every hour (3600000 ms for 1 hour)

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }
  }, [positions, bscStakes]);  // Trigger only when both positions and BSC stakes are ready

  if (loading) return <div>Loading pool data...</div>;
  if (!positions.length) return <div>No Uniswap V3 positions found.</div>;

  const chartData = {
    labels: usdValueLog.map(log => log.date),  // Remove reverse: old data on the left, new on the right
    datasets: [
      {
        label: 'Total Net USD Value',
        data: usdValueLog.map(log => log.value),  // Remove reverse: old data on the left, new on the right
        borderColor: '#00ccffd1',
        backgroundColor: 'rgba(0, 204, 255, 0.2)',
        fill: true,
      }
    ],
  };

  const containerStyles = {
    maxWidth: '80%',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '12px',
    border: '2px solid #00FFFF',
    boxShadow: '0 0 20px #00FFFF',
  };

  const thTdStyles = {
    border: '2px solid #00FFFF',
    padding: '10px',
    textAlign: 'left',
    fontFamily: 'Roboto Mono, monospace',
    color: '#FFFFFF',
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#00FFFF',
          font: {
            size: 14,
            family: 'Roboto Mono, monospace',
          },
        },
      },
      title: {
        display: true,
        text: 'Net USD Value Over Time',
        color: '#00FFFF',
        font: {
          size: 20,
          family: 'Roboto Mono, monospace',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#00FFFF',
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
        },
        grid: { color: 'rgba(0, 255, 255, 0.2)' },
      },
      y: {
        ticks: {
          color: '#00FFFF',
          font: {
            size: 12,
            family: 'Roboto Mono, monospace',
          },
        },
        grid: { color: 'rgba(0, 255, 255, 0.2)' },
      },
    },
  };

  return (
    <div className="uniswap-dashboard">
      <div style={containerStyles}>
        <h2 style={{
          color: '#00FFFF',
          textDecoration: 'underline',
          fontFamily: 'Orbitron, sans-serif',
          letterSpacing: '2px',
          textTransform: 'uppercase'
        }}>
          Arbitrum Land External Revenue Pools
        </h2>

        <h3 style={{
          color: '#F9EA76',
          fontFamily: 'Roboto Mono, monospace',
          letterSpacing: '1px',
          fontWeight: 'bold'
        }}>
          Yield is injected into the Arbland TVL to support the protocol&apos;s long-term growth
        </h3>

        <h2 style={{
          color: '#00FFFF',
          fontFamily: 'Orbitron, sans-serif',
          letterSpacing: '1px',
          borderBottom: '2px solid #00FFFF',
          paddingBottom: '30px',
          textTransform: 'uppercase'
        }}>
           Injected to date: {injectedValue} $ETH
        </h2>

        <table style={{
          width: '100%',
          marginBottom: '20px',
          margin: '0 auto',
          maxWidth: '900px',
          padding: '10px',
        }}>
          <thead>
    <tr>
      <th style={thTdStyles}>Liquidity Pool</th>
      <th className="chain-column" style={thTdStyles}>Chain</th> {/* Add class to "Chain" column */}
      <th style={thTdStyles}>Net USD Value</th>
      <th style={thTdStyles}>Claimable Tokens</th> {/* New column for claimable tokens */}
    </tr>
  </thead>
  <tbody>
    {positions.map((position, index) => {
      const poolNames = ['ETH/PEPE', 'ETH/GMX', 'ETH/ETHFI', 'ETH/ARB', 'PENDLE/USDC', 'ETH/ANDY'];  // Manually added pool names
      return (
        <tr key={index}>
          <td style={thTdStyles}>{poolNames[index] || 'Loading...'}</td>  {/* Manually added pool name */}
          <td className="chain-column" style={thTdStyles}>{position.pool.chain || 'Loading...'}</td>  {/* Add class to "Chain" column */}
          <td style={thTdStyles}>{position.stats?.net_usd_value ? `$${position.stats.net_usd_value.toFixed(2)}` : 'Loading...'}</td>
          <td style={thTdStyles}>
            {position.claimableTokens.length > 0 ? (
              position.claimableTokens.map((token, i) => (
                <div key={i}>
                  {token.symbol}: {token.amount.toFixed(4)}  {/* Limit claimable tokens to 4 decimals */}
                </div>
              ))
            ) : 'No claimable tokens'}
          </td>
        </tr>
      );
    })}

    {/* Add BSC Staking Data */}
    <tr>
      <td style={thTdStyles}>PACA.FINANCE</td>  {/* Pool name */}
      <td className="chain-column" style={thTdStyles}>bsc</td>  {/* Chain */}
      <td style={thTdStyles}>${bscStakes.combinedStakeValueUSD}</td>  {/* Combined stake value in USD */}
      <td style={thTdStyles}>${bscStakes.claimableUSD}</td>  {/* Claimable value in USD */}
    </tr>
  </tbody>
          <tfoot>
  <tr>
    <td colSpan="2" style={thTdStyles}>Total</td>
    <td style={thTdStyles}>
      ${(
        positions.reduce((total, pos) => total + (pos?.stats?.net_usd_value || 0), 0)
        + parseFloat(bscStakes.combinedStakeValueUSD)  // Add BSC staking value
      ).toFixed(2)}
    </td>
    <td style={thTdStyles}>-</td> {/* No total for claimable tokens */}
  </tr>
</tfoot>
        </table>

        <div style={{
          marginTop: '20px',
          width: '80%',
          margin: '0 auto',
          maxWidth: '900px',
          height: '400px',
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}>
          <Line
            data={chartData}
            options={chartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default UniswapDashboard;