// fetchInjectedValue.js
import { Contract, JsonRpcProvider, formatEther } from "ethers";
import contractABI from './abi.json';

const CONTRACT_ADDRESS = "0xafBD3dBfA79d1B271E8dE61161017FE1265241d4"; 
const ARBITRUM_RPC_URL = "https://floral-damp-hexagon.arbitrum-mainnet.quiknode.pro/da170ea75d3d2db544502b912794b6d4b15a9a40/";

// Function to fetch the injected value from the smart contract
export const fetchInjectedValue = async () => {
  try {
    const provider = new JsonRpcProvider(ARBITRUM_RPC_URL);
    const contract = new Contract(CONTRACT_ADDRESS, contractABI, provider);
    const totalInjected = await contract.protocolTotalInjected();

    // Convert from Wei to Ether
    return formatEther(totalInjected);
  } catch (error) {
    console.error("Error fetching injected value:", error);
    return "0";
  }
};

// Function to track each injection using the EmitInjection event
export const trackInjections = (onInjection) => {
  try {
    const provider = new JsonRpcProvider(ARBITRUM_RPC_URL);
    const contract = new Contract(CONTRACT_ADDRESS, contractABI, provider);

    // Listen for the EmitInjection event
    contract.on("EmitInjection", (injectedAmount, event) => {
      const amountInEther = parseFloat(formatEther(injectedAmount));
      const timestamp = new Date().toISOString();

      console.log(`New injection detected: ${amountInEther} ETH at ${timestamp}`);

      // Trigger the callback function with the injection details
      if (onInjection) {
        onInjection({ amount: amountInEther, timestamp });
      }
    });
  } catch (error) {
    console.error("Error tracking injections:", error);
  }
};