import React, { useEffect, useState } from "react";
import { fetchInjectedValue } from './fetchInjectedValue';

const UniswapDashboard = () => {
  const [injectedValue, setInjectedValue] = useState(1.665);

  useEffect(() => {
    const fetchInjected = async () => {
      try {
        const value = await fetchInjectedValue();
        setInjectedValue(value);
      } catch (error) {
        console.error('Error fetching injected value:', error);
      }
    };

    fetchInjected();
    const interval = setInterval(fetchInjected, 3600000);
    return () => clearInterval(interval);
  }, []);

  const containerStyles = {
    maxWidth: '80%',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '12px',
    border: '2px solid #00FFFF',
    boxShadow: '0 0 20px #00FFFF',
  };

  return (
    <div className="uniswap-dashboard">
      <div style={containerStyles}>
        <h2 style={{
          color: '#00FFFF',
          textDecoration: 'underline',
          fontFamily: 'Orbitron, sans-serif',
          letterSpacing: '2px',
          textTransform: 'uppercase'
        }}>
          Arbitrum Land External Revenue Pools
        </h2>

        <h3 style={{
          color: '#F9EA76',
          fontFamily: 'Roboto Mono, monospace',
          letterSpacing: '1px',
          fontWeight: 'bold'
        }}>
          Yield is injected into the Arbland TVL to support the protocol&apos;s long-term growth
        </h3>

        <h2 style={{
          color: '#00FFFF',
          fontFamily: 'Orbitron, sans-serif',
          letterSpacing: '1px',
          borderBottom: '2px solid #00FFFF',
          paddingBottom: '30px',
          textTransform: 'uppercase'
        }}>
          Injected to date: {injectedValue} $ETH
        </h2>
      </div>
    </div>
  );
};

export default UniswapDashboard;