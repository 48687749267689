import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal"; // Using the existing Modal component
import { InfoBox } from "../../themes/global";
import logoGif from "../../assets/logo.gif"; // Import the GIF

const Dragons = ({ closeModal }) => {
  const [compoundFee, setCompoundFee] = useState(0);
  const [actionCooldown, setActionCooldown] = useState(0);
  const [availableRewards, setAvailableRewards] = useState(0);
  const [availableRewardsInEth, setAvailableRewardsInEth] = useState(0);
  const [loading, setLoading] = useState(true);

  const close = () => {
    window.clearAllIntervals();
    closeModal();
  };

  const compound = async () => {
    setLoading(true);
    const compoundFee = await window.contract.methods.COMPOUND_FEE().call();
    await window.compound(compoundFee);
    await updateStateValues();
    setLoading(false);
  };

  const calculateActionCD = (lastAction, actionCoolDown) => {
    const now = Math.floor(new Date().getTime() / 1000);
    let timeLeft = lastAction + actionCoolDown - now;
    timeLeft = (timeLeft < 0 ? 0 : timeLeft) / 60 / 60;
    timeLeft = Math.round(timeLeft * 100) / 100;
    console.log("User Action CD", timeLeft);
    return timeLeft;
  };

  const startActionCDCalculation = (lastAction, actionCoolDown) => {
    setInterval(() => {
      const timeLeft = calculateActionCD(lastAction, actionCoolDown);
      setActionCooldown(timeLeft);
    }, 1000);
  };

  const updateStateValues = async () => {
    const userInfo = await window.getUserInfo();
    window.clearAllIntervals();
    if (window.hasValidWeb3Connection === true && userInfo) {
      const compoundFee = await window.contract.methods.COMPOUND_FEE().call();
      compoundFee && setCompoundFee(window.readableETH(compoundFee, 3));
      const rewards = await window.contract.methods.getAvailableRewards(window.currentAddr).call();
      rewards && setAvailableRewards(rewards);
      const rewardsInEth = await window.contract.methods.calculateSell(window.currentAddr).call();
      rewardsInEth && setAvailableRewardsInEth(window.readableETH(rewardsInEth, 5));
      const actionCoolDown = await window.contract.methods.ACTION_COOLDOWN().call();
      const timeLeft = calculateActionCD(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
      setActionCooldown(timeLeft);
      startActionCDCalculation(parseInt(userInfo._lastAction), parseInt(actionCoolDown));
    }
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await updateStateValues();
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      {/* Place the GIF above the modal container */}
      <div className="dragons-gif-container">
        <img src={logoGif} alt="Logo GIF" className="dragons-logo-gif" />
      </div>

      <Modal
        closeModal={close} title={
          <div style={{ textAlign: 'center' }}>
            <span className="tower-modal-title">Compound Sacrifice</span>
            <h5 style={{ margin: 0, color: '#888' }}>Here be Dragons</h5>
            </div>
        }
      >
        <div className="dragons-modal-content">
          <p className="dragons-quote">
            &quot;Compound interest is the eighth wonder of the world. He who understands it, earns it ... he who doesn&apos;t ... pays it.&quot;
            <br />
            <span>- Albert Einstein</span>
          </p>
          <div className="info-box-container">
            <InfoBox className="dragons-info-box">
              <h3>COMPOUND FEE</h3>
              <p>{compoundFee} $ETH</p>
            </InfoBox>
            <InfoBox className="dragons-info-box">
              <h3 className="dinfo-box-text">REWARDS</h3>
              <div className="rewards-wrapper">
                <span className="dinfo-box-text">{parseInt(availableRewards)?.toLocaleString()} KNIGHTS</span><br />
                <span className="dinfo-box-text">{availableRewardsInEth} $ETH</span>
              </div>
            </InfoBox>
            <InfoBox className="dragons-info-box">
              <h3>ACTION COOLDOWN</h3>
              <p>{actionCooldown} HOURS</p>
            </InfoBox>
          </div>
          <button
            disabled={loading || !window.hasValidWeb3Connection || actionCooldown > 0}
            className="dragons-button"
            onClick={() => compound()}
          >
            COMPOUND
          </button>

          {/* Add the new text below the button */}
          <p className="dragons-invest-text">
            INVEST FRESH $ETH TO COMPOUND YOUR EARNINGS AND GROW YOUR KNIGHTS!
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Dragons;