import React, { useState, useEffect } from "react";
import Modal from "../shared/Modal";
import Tree from "react-d3-tree";

const Ufo = ({ closeModal }) => {
  const [referrals, setReferrals] = useState(0);
  const [referralRewards, setReferralRewards] = useState(0);
  const [referralValueOnboarded, setReferralValueOnboarded] = useState(0);
  const [referralTier, setReferralTier] = useState(null);
  const [upline, setUpline] = useState('');
  const [referralRewardPercentage, setReferralRewardPercentage] = useState(0);
  const [referralTreeData, setReferralTreeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedNodes, setExpandedNodes] = useState({});

  const close = () => {
    closeModal();
  };

  const getReferralRewardPercentageByTier = (tier) => {
    switch (tier) {
      case 1: return 1;
      case 2: return 2;
      case 3: return 3;
      case 4: return 5;
      case 5: return 8;
      case 6: return 12;
      case 7: return 15;
      case 8: return 20;
      default: return 0;
    }
  };

  const handleNodeClick = (nodeName) => {
    setExpandedNodes(prevState => ({
      ...prevState,
      [nodeName]: !prevState[nodeName],
    }));
  };

  const fetchReferralTree = async (userAddress) => {
    try {
      const events = await window.contract.getPastEvents("EmitBought", {
        filter: { ref: userAddress },
        fromBlock: 0,
        toBlock: 'latest',
      });

      const walletInvestmentCounts = {};
      const walletEventMap = {};
      const uniqueReferrals = new Set();

      // Group events by wallet and count the investments
      events.forEach(event => {
        const wallet = event.returnValues.adr.toLowerCase();

        if (wallet !== userAddress.toLowerCase()) {
          if (walletInvestmentCounts[wallet]) {
            walletInvestmentCounts[wallet] += 1;
            walletEventMap[wallet].amount = (parseFloat(walletEventMap[wallet].amount) + parseFloat(window.web3.utils.fromWei(event.returnValues.amount, 'ether'))).toString();
          } else {
            walletInvestmentCounts[wallet] = 1;
            walletEventMap[wallet] = {
              amount: window.web3.utils.fromWei(event.returnValues.amount, 'ether'),
              miners: event.returnValues.miners,
            };
          }
          uniqueReferrals.add(wallet);
        }
      });

      const referralTree = {
        name: `You`,
        children: Array.from(uniqueReferrals).map(wallet => ({
          name: `Referral: ${wallet}`,
          amount: walletEventMap[wallet].amount,
          miners: walletEventMap[wallet].miners,
          investmentCount: walletInvestmentCounts[wallet],
          children: [],
        })),
      };

      setReferralTreeData(referralTree);

      return uniqueReferrals;
    } catch (error) {
      console.error("Error fetching referral tree:", error);
      return new Set();
    }
  };

  const updateReferralData = async () => {
    if (window.hasValidWeb3Connection && window.currentAddr) {
      const userInfo = await window.contract.methods.getUserInfo(window.currentAddr).call();

      setReferrals(Number(userInfo._referrals));
      setReferralRewards(window.web3.utils.fromWei(userInfo._referralRewards, 'ether'));
      setReferralValueOnboarded(window.web3.utils.fromWei(userInfo._referralValueOnboarded, 'ether'));
      setUpline(userInfo._referrer);

      const referralValueOnboardedInEth = parseFloat(window.web3.utils.fromWei(userInfo._referralValueOnboarded, 'ether'));
      let calculatedTier = 1;

      if (referralValueOnboardedInEth >= 1500) {
        calculatedTier = 8;
      } else if (referralValueOnboardedInEth >= 450) {
        calculatedTier = 7;
      } else if (referralValueOnboardedInEth >= 150) {
        calculatedTier = 6;
      } else if (referralValueOnboardedInEth >= 50) {
        calculatedTier = 5;
      } else if (referralValueOnboardedInEth >= 15) {
        calculatedTier = 4;
      } else if (referralValueOnboardedInEth >= 5) {
        calculatedTier = 3;
      } else if (referralValueOnboardedInEth >= 1) {
        calculatedTier = 2;
      }

      setReferralTier(calculatedTier);
      const rewardPercentage = getReferralRewardPercentageByTier(calculatedTier);
      setReferralRewardPercentage(rewardPercentage);

      const uniqueReferrals = await fetchReferralTree(window.currentAddr);
      setReferrals(uniqueReferrals.size);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await updateReferralData();
      } catch (error) {
        console.error("Error updating referral data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Modal
        closeModal={close}
        title={
          <div style={{ textAlign: 'center' }}>
            <span className="modal-title">Referral Dashboard</span>
            <h5 style={{ margin: 0, color: '#888' }}>Grow your $ETH Empire</h5>
          </div>
        }
      >
        {loading ? (
          <p style={{ fontSize: '1rem', padding: '10px' }}>Loading referral data...</p>
        ) : (
          <>
            <div style={{ fontSize: '1rem', marginBottom: '1rem', color: 'white' }}>
              Total Referrals: <strong>{referrals}</strong>
            </div>
            <div style={{ fontSize: '1rem', marginBottom: '1rem', color: 'white' }}>
              Total Referral Rewards: <strong>{referralRewards} $ETH</strong>
            </div>
            <div style={{ fontSize: '1rem', marginBottom: '1rem', color: 'white' }}>
              Total Value Onboarded: <strong>{referralValueOnboarded} $ETH</strong>
            </div>
            <div style={{ fontSize: '0.5rem', marginBottom: '1rem', color: 'white' }}>
              Your Upline: <strong>{upline === '0x0000000000000000000000000000000000000000' ? '(no upline)' : upline}</strong>
            </div>
            <div style={{ fontSize: '1rem', marginBottom: '1rem', color: 'white' }}>
              Referral Tier: <strong>{referralTier}</strong>
            </div>
            <div style={{ fontSize: '1rem', marginBottom: '1rem', color: 'white' }}>
              Referral Reward Percentage: <strong>{referralRewardPercentage}%</strong>
            </div>

            {referralTreeData && (
              <div
                style={{
                  height: '500px',
                  width: '90%',
                  margin: '20px auto',
                  padding: '20px',
                  background: 'linear-gradient(135deg, rgba(50, 23, 77, 0.8), rgba(25, 57, 37, 0.8))',
                  borderRadius: '10px',
                  border: '3px solid #00ccffd1',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  color: 'white',
                }}
              >
                <h2 style={{ color: '#FFFFFF', marginBottom: '20px' }}> 🌳 Your Arbland Referral Tree 🌳 </h2>
                <h4 style={{ color: '#FFFFFF', marginBottom: '0px', padding: '0px', marginTop: '0px' }}>
                  (Click the Orbs to reveal the data)
                </h4>
                <div
                  className="tree-container"
                  style={{
                    width: '100%',
                    height: '45vh',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <Tree
                    data={referralTreeData}
                    orientation="horizontal"
                    translate={{ x: 100, y: 200 }}
                    nodeSize={{ x: 150, y: 100 }}
                    zoom={0.5}
                    scaleExtent={{ min: 0.2, max: 2 }}
                    pathFunc="straight"
                    renderCustomNodeElement={({ nodeDatum }) => (
                      <g onClick={() => handleNodeClick(nodeDatum.name)}>
                        <circle r={10} fill="#00ccffd1" />
                        {nodeDatum.name === 'You' ? (
                          <text fill="white" x={20} style={{ fontSize: '0.8rem', fontWeight: 'bold' }} stroke="none">You</text>
                        ) : (
                          expandedNodes[nodeDatum.name] && (
                            <>
                              <text fill="white" x={20} style={{ fontSize: '0.7rem', fontWeight: 'bold' }} stroke="none">
                                {nodeDatum.name} ({nodeDatum.investmentCount})
                              </text>
                              <text fill="white" x={20} y={20} style={{ fontSize: '0.7rem' }} stroke="none">
                                Invested: {nodeDatum.amount} $ETH
                              </text>
                            </>
                          )
                        )}
                      </g>
                    )}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};



export default Ufo;