import styled from "styled-components";

export const coralPurple = "#592464";
export const lightOrange = "#ff9e00";
export const orange = "#ff7900";
export const midnightBlue = "#003366";
export const fatedOrange = "#c79568";
export const purpleGradientTransparent = 'linear-gradient(135deg, rgba(50, 23, 77, 0.5), rgba(25, 57, 37, 0.5))';
export const blue = "#00ccffd1"; /* Light blue solid outline */
export const white = "#ffffff"; /* White text color */

// Define your styled components
export const IndexContainer = styled.div`
    width: 100%;
    height: 100%;
    @media screen and (min-width: 1024px) {
        position: relative;
        margin: 0 auto;
        height: auto;
        width: ${props => props.width}px;
    }
`;

export const ImgOutline = styled.div`
    box-shadow: 0px 0px 10px 0 ${midnightBlue}; 
    position: relative;
    height: 8vh; /* Adjust height to fit your design */
    width: 45%;  /* Slightly less than 50% to account for margins */
    margin: 10px 1%;  /* Space between the buttons */
    border-radius: 10px;
    background: ${purpleGradientTransparent}; /* Apply the gradient background */
    border: thin solid ${blue}; 
    opacity: 1;
    text-align: center; /* Center the text */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    box-sizing: border-box; /* Ensure padding is included in width */

    &:hover {
        transform: scale(1.05); /* Slightly enlarge on hover */
    }

    @media screen and (max-width: 768px) {
        width: 44%; /* Make the buttons full width on smaller screens */
    }
`;

export const ModalContainer = styled.div`
    background: ${purpleGradientTransparent}; /* Apply the gradient background similar to the main page */
    border-radius: 15px; /* More rounded corners for a modern look */
    border: thin solid ${blue}; /* Use a thin orange border to match the theme */
    padding: 20px; /* Add padding for better spacing inside the modal */
    box-shadow: 0px 0px 15px 0 ${midnightBlue}; /* Subtle shadow for depth */
    max-width: 90vw; /* Ensure the modal is responsive and fits well on mobile */
    margin: 0 auto; /* Center the modal horizontally */
    text-align: center; /* Center the text */
    box-sizing: border-box; /* Ensure padding is included in the width */
    display: flex; /* Flexbox for vertical centering */
    flex-direction: column; /* Column layout for contents */
    justify-content: center; /* Center contents vertically */
`;

export const InfoBoxContainer = styled.div`
    display: flex;
    flex-wrap: wrap; /* Allow the boxes to wrap to the next line */
    justify-content: space-between; /* Distribute space evenly between boxes */
    padding: 20px; /* Add padding for spacing */
    gap: 10px; /* Space between the InfoBox elements */
`;

export const InfoBox = styled.div`
    background: ${purpleGradientTransparent}; /* Match the main page's gradient */
    border: thin solid ${blue}; /* Consistent border with the modal */
    border-radius: 10px; /* Rounded corners */
    padding: 15px; /* Padding for content inside the box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow for better visuals */
    color: ${white}; /* White text for general readability */
    font-family: 'Roboto', sans-serif; /* Modern font matching the main page */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    width: 30%; /* Set width to allow three per row */
    box-sizing: border-box; /* Ensure padding is included in the width */

    h3, p {
        font-size: 0.85em; /* Relative to the parent box size */
        margin: 0; /* Remove default margins */
        line-height: 1.2; /* Adjust line height for better fit */
    }

    .info-box-text, .dinfo-box-text {
        font-size: 0.85em; /* Relative to the parent box size */
        margin: 0; /* Remove default margins */
        line-height: 1.2; /* Adjust line height for better fit */
        color: ${white}; /* General text color */
    }

    /* Apply yellow color specifically to KNIGHTS and ETH rewards in both Castle and Dragon modals */
    .rewards-wrapper {
        .info-box-text, .dinfo-box-text {
            color: #00CCFF; /* Yellow color for KNIGHTS and ETH rewards */
        }
    }

    @media screen and (min-width: 1300px) {
        h3, p, .info-box-text, .dinfo-box-text {
            font-size: 1.2em; /* Larger font size for bigger screens */
        }

    @media screen and (min-width: 1681px) {
        h3, p, .info-box-text, .dinfo-box-text {
            font-size: 1.5em; /* Larger font size for bigger screens */
        }
    }

    &:hover {
        border-color: ${fatedOrange}; /* Change border color to orange */
        transform: none; /* Remove any scaling or translation effects */
        box-shadow: none; /* Remove any shadow changes */
        transition: border-color 0.3s ease; /* Smooth transition for the border color */
    }
`;