import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit, where } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtiZTizZMixf63Rz7fxIKsqJVnoK2Kc9k",
  authDomain: "arbland-23051.firebaseapp.com",
  projectId: "arbland-23051",
  storageBucket: "arbland-23051.appspot.com",
  messagingSenderId: "770441222591",
  appId: "1:770441222591:web:33c510ad55631d0a35a90a",
  measurementId: "G-MB2NZJ420C"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Function to log chart data to Firestore
export const logDataToFirestore = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "chartData"), data);
    console.log("Data written to Firestore with ID: ", docRef.id);
    console.log("Logged data:", data);  // Log the data being added
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

// Function to fetch chart data from Firestore
export const fetchChartDataFromFirestore = async () => {
  const q = query(
    collection(db, "chartData"),
    where("poolName", "==", "Total USD Value"),  // Filter for total value
    orderBy("timestamp", "desc"),
    limit(90)
  );
  const querySnapshot = await getDocs(q);
  const chartData = [];
  querySnapshot.forEach((doc) => {
    console.log("Fetched document data:", doc.data());  // Log each document fetched
    chartData.push(doc.data());
  });
  return chartData;
};