import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/logo.png";
import Circle from "../../assets/circlelogo.png";
import EthPrice from "./EthPrice"; // Import the EthPrice component

const Header = ({ cssClass, toggleState }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const menuRef = useRef(null); // Ref for the hamburger menu

    // Handle outside clicks to close the menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const handleOnCLick = () => {
        toggleState();
    };

    const handleHamburgerClick = () => {
        setIsOpen(!isOpen);
    };

    const handleDocsClick = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleJoinSccClick = (e) => {
        e.stopPropagation();
        window.open("https://cryptomischief.io/scc-nft/", "_blank");
    };

    const handleTelegramClick = () => {
        window.open("https://t.me/cryptomischief2", "_blank");
    };

    const handleDiscordClick = () => {
        window.open("https://discord.gg/V7cgwUfTuD", "_blank");
    };

    // New function for Whitepaper click
    const handleWhitepaperClick = () => {
        window.open("https://cryptomischief.io/wp-content/uploads/Arbitrum-Land-WP.pdf", "_blank");
    };

    // New function for Merch click
    const handleMerchClick = () => {
        window.open("https://shop.cryptomischief.io", "_blank"); // Replace with the actual merch URL
    };

    return (
        <div className="header-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
            <button onClick={handleHamburgerClick} className="hamburger-button mobile-only">
                ☰
            </button>

            <div className="logo-container" style={{ flex: 1, textAlign: 'center', display: 'none' }}>
                <img src={Logo} alt="logo" style={{ maxHeight: '80px', maxWidth: '100px' }} />
            </div>
            <div className="circlelogo-container" style={{ flex: 1, textAlign: 'center', display: 'block' }}>
                <img src={Circle} alt="circle" style={{ maxHeight: '120px', maxWidth: '100px' }} />
            </div>

            <EthPrice /> {/* Aligns with other flex items */}

            <div onClick={() => window.connectWeb3()} className="connect-container">
                <button className="connect-button" id="enableMetamask">CONNECT</button>
                <img className="network-img" alt="network" id="network-img" src="images/arb-dicsonnected-logo.png" />
            </div>

            {isOpen && (
                <div className="menu-container mobile-only" ref={menuRef}>
                    <div className="mobile-menu">
                        <button onClick={handleDocsClick} className="menu-option">
                            DOCS
                            {isSubmenuOpen && (
                                <div className="submenu">
                                    <a onClick={stopPropagation} className="submenu-option" href="https://shitcoin-club.gitbook.io/arbitrum-land/how-to-play-to-win-the-basics">How to Play</a>
                                    <a onClick={stopPropagation} className="submenu-option" href="#">Contract</a>
                                    <a onClick={stopPropagation} className="submenu-option" href="#">Audit</a>
                                </div>
                            )}
                        </button>
                        <button onClick={handleWhitepaperClick} className="menu-option">WHITEPAPER</button>
                        <button onClick={handleJoinSccClick} className="menu-option">JOIN SCC</button>
                        <button onClick={handleMerchClick} className="menu-option">MERCH</button> {/* Added MERCH button */}
                        <button onClick={handleTelegramClick} className="menu-option">TELEGRAM</button>
                        <button onClick={handleDiscordClick} className="menu-option">DISCORD</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;